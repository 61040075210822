<template>
  <q-page class="wishlistPage">
    <template v-if="isLoggedIn">
      <template v-if="loading && !isMobile">
        <div class="page-title">
          <div class="row items-center full-width">
            <div class="col-6 col-md-6 q-my-xs">
              <q-skeleton width="200px" height="20px" />
              <q-skeleton
                type="text"
                width="160px"
                height="20px"
                class="q-mt-sm"
              />
            </div>
            <div class="col-12 col-md-2 offset-md-4 col-lg-1 offset-lg-5">
              <q-skeleton width="100%" height="20px" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="page-title">
          <div class="page-title--left" v-if="!isMobile">
            <h1 class="text-capitalize">
              {{ wishlistTitle }} ({{ totalCount }})
            </h1>
            <q-breadcrumbs>
              <q-breadcrumbs-el label="Home" :to="{ name: 'Home' }" />
              <q-breadcrumbs-el
                label="Wishlist"
                :to="{ name: 'ManageWishlist' }"
              />
              <q-breadcrumbs-el :label="wishlistTitle" />
            </q-breadcrumbs>
          </div>
          <div
            v-show="totalCount > 0"
            :class="
              !isMobile ? 'page-title--right' : 'page-title--right q-ml-auto'
            "
          >
            <div class="wishlist-action">
              <q-btn
                unelevated
                color="primary"
                v-if="
                  isAnySelected &&
                  isProductInCart() &&
                  selectedFulfillmentMethodID &&
                  isOutOfStock()
                "
                @click="addToCart"
                :padding="isMobile ? '6px sm' : '6px md'"
              >
                Add to Cart
              </q-btn>
              <q-btn
                unelevated
                outline
                color="negative"
                class="q-ml-xs"
                v-if="isAnySelected"
                @click="deleteAllWishlist"
              >
                Delete
              </q-btn>
            </div>
            <div class="q-ml-sm q-ml-md-lg q-mr-md-md">
              <q-checkbox
                value="Demo"
                v-model="selectAll"
                dense
                label="Select All"
              ></q-checkbox>
            </div>
            <q-skeleton v-if="loadingCategory" width="100px" height="40px" />

            <div class="showitems flex items-center q-ml-sm" v-if="!isMobile">
              <span>Sort:</span>
              <q-select
                dense
                borderless
                :value="wishlistFilters.sortBy"
                :options="sortByOptions"
                option-value="value"
                option-label="label"
                emit-value
                map-options
                transition-show="jump-up"
                transition-hide="jump-up"
                @input="
                  (val) => {
                    sortByModified(val)
                    refreshListing(val)
                  }
                "
              />
            </div>
            <ProductLayoutIcons
              class="q-ml-auto"
              v-if="!isMobile"
              @changeView="changeView"
              ref="productLayoutIcons"
              :isListView="!isMobile ? wishlistFilters.isListView : false"
            />
          </div>
        </div>
        <template v-if="totalCount > 0 && isMobile">
          <FilterButtons
            :showFilterBtn="false"
            :sortBtnClick="
              () => {
                this.showSortByDialog = true
              }
            "
            filterBtnName="Category"
          />

          <q-dialog
            v-model="showSortByDialog"
            position="bottom"
            transition-hide="slide-down"
            transition-show="slide-up"
            maximized
          >
            <q-card class="mobileCardDialogue">
              <q-item-label class="mobileSortFilter">
                <h5 class="text-h5">Sort by</h5>
                <q-btn class="close" flat dense v-close-popup>
                  <q-icon class="close cursor-pointer" name="close" />
                </q-btn>
              </q-item-label>
              <q-option-group
                class="q-pa-md"
                :options="sortByOptions"
                type="radio"
                :value="wishlistFilters.sortBy"
                @input="
                  (val) => {
                    sortByModified(val)
                    refreshListing(val)
                  }
                "
              />
            </q-card>
          </q-dialog>
        </template>
      </template>

      <div class="page-content favouriteItems">
        <q-infinite-scroll
          @load="fetchNext"
          :offset="1500"
          class="product-items"
          ref="infiniteScroll"
        >
          <ProductCard
            v-for="(product, index) in wishlist"
            :product="product"
            :hideWishlist="true"
            :showCheckMark="true"
            :key="product.productID + '-' + index"
            @removeItemFromFavorites="scrollPosition"
            :class="{
              'list-view': !isMobile ? wishlistFilters.isListView : false,
            }"
            :isListView="!isMobile ? wishlistFilters.isListView : false"
            @toggleSelection="toggleSelection"
            :selectedProducts="selectAll || selected[product.productID]"
          />
          <template v-slot:loading>
            <ProductSkeleton
              v-show="loading"
              :isListView="!isMobile ? wishlistFilters.isListView : false"
            />
            <LoadMoreSpinner v-show="!loading" />
          </template>
        </q-infinite-scroll>
      </div>

      <EmptyComponent
        v-show="!loading && totalCount == 0"
        image="images/empty-wishlist.png"
        btnText="Start Shopping"
        to="/"
      >
        <p>You don't have Product in this wishlist.</p>
      </EmptyComponent>
    </template>
    <EmptyComponent
      v-else
      image="images/empty-wishlist.png"
      btnText="Sign In"
      btnClass="q-pr-lg q-pl-lg q-mt-sm "
      :btnClick="
        () =>
          this.changeDialogState({
            dialog: 'accountDrawer',
            val: true,
          })
      "
    >
      <p>Sign in or create an account to use your list.</p>
    </EmptyComponent>

    <CreateWishListDialog />
  </q-page>
</template>

<script>
import ProductCard from 'components/ProductCard'
import { mapGetters } from 'vuex'
import ProductLayoutIcons from 'components/common/ProductLayoutIcons'
import { hideShowFullSpinner } from 'src/utils'
import CreateWishListDialog from 'src/components/common/CreateWishlistDialog.vue'
import { testModePaymentMessage } from 'src/constants'

export default {
  name: 'PageWishlists',
  /* preFetch({ store }) {
    let promise = store.dispatch('wishlist/getAllWishlist')
    if (process.env.SERVER) return promise
  }, */
  components: {
    ProductCard,
    FilterButtons: () => import('components/common/FilterButtons'),
    // lazy load component
    ProductSkeleton: () => import('components/category/ProductSkeleton'),
    ProductLayoutIcons,
    CreateWishListDialog,
  },
  meta() {
    return {
      title: this.wishlistTitle || 'Wishlists',
      ...this.commonMeta,
    }
  },
  data() {
    return {
      showSortByDialog: false,
      showCategoryDialog: false,
      parameters: {},
      scrollCounter: 0,
      selected: {},
      loading: true,
    }
  },
  computed: {
    // ...mapGetters('common', ['fulFillments']),
    ...mapGetters('persisted', ['wishlistFilters', 'currentLocationId']),
    isAnySelected() {
      return Object.keys(this.selected).length > 0
    },
    wishlistTitle() {
      const wishlistName = this.getWishlistNameByID(
        this.$router.currentRoute.params.wishListID
      )
      return wishlistName
    },
    selectAll: {
      get: function () {
        if (Object.keys(this.selected).length == 0) return false

        if (
          Object.keys(this.selected).length !==
          Object.keys(this.wishlist).length
        )
          return false

        return true
      },
      set: function (newVal) {
        if (newVal)
          this.selected = Object.keys(this.wishlist).reduce((object, key) => {
            object[key] = true
            return object
          }, {})
        else this.selected = {}
      },
    },
    objRequest() {
      return {
        Sorts: this.wishlistFilters.sortBy,
        PageSize: this.parameters.PageSize,
      }
    },
    ...mapGetters('wishlist', [
      'sortByOptions',
      'wishlist',
      'defaultParams',
      'nextPageNumber',
      'totalCount',
      'loadingCategory',
      'allowAddToCart',
      'selectedFulfillmentMethodID',
      'getWishlistNameByID',
    ]),
    ...mapGetters('cart', ['currentCartDetails']),
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    wishlistFilters() {
      return this.getFilterByPage('wishlist')
    },
  },
  async created() {
    this.parameters = Object.assign({}, this.parameters, this.defaultParams)
  },
  watch: {
    currentCartDetails: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.isProductInCart()
        }
      },
      deep: true,
    },
    // fulFillments: {
    //   handler(newVal, oldVal) {
    //     if (newVal != oldVal) {
    //       for (let key in newVal) {
    //         if (
    //           newVal[key].available &&
    //           !newVal[key].checked &&
    //           newVal[key].fulfillmentMethodID ==
    //             this.selectedFulfillmentMethodID
    //         ) {
    //           this.$store.commit('wishlist/RESET_ADD_TO_CART_WISHLIST', '')
    //         }
    //       }
    //     }
    //   },
    //   deep: true,
    // },
  },
  methods: {
    sortByModified(sortBy) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'wishlist',
        filter: {
          ...this.wishlistFilters,
          sortBy,
        },
      })
    },
    changeView(isListView) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'wishlist',
        filter: {
          ...this.wishlistFilters,
          isListView,
        },
      })
    },
    fetchNext(index, done) {
      let params = {
        Page: index,
        ...this.objRequest,
        locationID: this.currentLocationId,
        zipCode: this.$store.getters['persisted/locationOrShippingZip'],
      }
      let WishListID = this.$router.currentRoute.params.wishListID
      if (index == 1) this.loading = true
      this.$store
        .dispatch('wishlist/getWishlistProducts', { WishListID, params })
        .then((hasNext) => {
          done(!hasNext)
          if (!this.isMobile && this.$refs.productLayoutIcons) {
            this.$refs.productLayoutIcons.toggleView(
              this.wishlistFilters.isListView ? 'list' : 'grid'
            )
          }
        })
        .finally(() => {
          if (index == 1) this.loading = false
        })
    },
    refreshListing(value) {
      this.$store.commit('wishlist/SET_WISHLIST', {})
      this.$refs.infiniteScroll.reset()
      this.$refs.infiniteScroll.resume()
      this.$refs.infiniteScroll.trigger()
      this.showSortByDialog = false
      this.showCategoryDialog = false
    },
    scrollPosition() {
      this.scrollCounter += 1
      if (
        this.scrollCounter % this.parameters.PageSize == 0 &&
        this.totalCount > 0
      )
        this.$root.$emit('scroll-to-position', Math.floor(Math.random() * 200))
    },
    isProductInCart() {
      let showBtn = false
      let selectedProducts = [],
        inCartProducts = []
      for (let key in this.selected) {
        selectedProducts.push(key)
      }

      if (this.currentCartDetails) {
        for (let fId in this.currentCartDetails.fulfillments) {
          const fulfillment = this.currentCartDetails.fulfillments[fId]
          for (let olId in fulfillment.shoppingCartItems) {
            let item = fulfillment.shoppingCartItems[olId]
            inCartProducts.push(item.productId)
          }
        }
      }

      for (let selectedProduct of selectedProducts) {
        if (!inCartProducts.includes(selectedProduct)) {
          showBtn = true
          break
        }
      }
      return showBtn
    },
    toggleSelection(productId) {
      if (this.selected.hasOwnProperty(productId)) {
        this.$delete(this.selected, productId)
      } else {
        this.selected = {
          ...this.selected,
          [productId]: true,
        }
      }
      this.isProductInCart()
      this.isOutOfStock()
    },
    isOutOfStock() {
      let selectedProducts = []
      for (let key in this.selected) {
        if (this.wishlist[key]) selectedProducts.push(this.wishlist[key])
      }
      let isSelectedProductOutOfStock = false
      if (selectedProducts.length) {
        isSelectedProductOutOfStock = selectedProducts.every((product) => {
          return product.isinstock == false
        })
        return !isSelectedProductOutOfStock
      }
    },
    getSelectedProducts() {
      let wishProducts = []
      if (this.selectAll) {
        for (let productID in this.wishlist) {
          wishProducts.push(productID)
        }
        return wishProducts
      } else {
        for (let productID in this.selected) {
          wishProducts.push(productID)
        }
        return wishProducts
      }
    },
    deleteAllWishlist() {
      this.ftxConfirm('Are you sure you want to Delete?')
        .onOk(() => {
          hideShowFullSpinner(true)
          this.$store
            .dispatch('wishlist/removeAllItemFromWishlist', {
              wishListID: this.$route.params.wishListID,
              productIds: this.getSelectedProducts(),
            })
            .then((res) => {
              this.selectAll = false
              this.selected = {}
            })
            .catch((e) => {
              console.log('Error', e)
            })
            .finally(() => hideShowFullSpinner(false))
        })
        .onCancel(() => {})
        .onDismiss(() => {})
    },
    async addToCart() {
      if (
        this.businessConfigurations.mode === 2 &&
        this.businessConfigurations.currentPaymentGateway == null
      ) {
        this.showError(testModePaymentMessage)
      } else {
        let objRequest = {
          fulfillmentMethodId: this.selectedFulfillmentMethodID,
        }
        let products = []
        for (let key in this.selected) {
          let product = this.wishlist[key]
          if (product.hasOwnProperty('productID') && product.isinstock) {
            products.push({
              productId: product.productID,
              qty: 1,
            })
          }
        }
        objRequest.products = products
        // return objRequest
        hideShowFullSpinner(true)
        let response = await this.$store.dispatch(
          'cart/addUpdateCartDetail',
          objRequest
        )
        if (response?.success) {
          this.notify('Item Added to Cart.')
        }

        hideShowFullSpinner(false)
      }
    },
    notify(message) {
      this.showSuccess(
        message,
        this.$route.name != 'Cart'
          ? [
              {
                color: 'dark',
                label: 'View Cart',
                color: 'orange-5',
                handler: () => {
                  if (this.isMobile)
                    this.$router
                      .push({
                        name: 'Cart',
                      })
                      .catch(() => {})
                  else
                    this.changeDialogState({
                      dialog: 'cartDrawer',
                      val: true,
                    })
                },
              },
            ]
          : []
      )
    },
  },
  destroyed() {
    this.$store.commit('wishlist/RESET_WISHLISTPRODUCT')
  },
  mounted() {
    // prefetch code
    this.$store.dispatch('wishlist/getAllWishlist')
    // prefetch code
  },
}
</script>
<style lang="scss">
.wishlistPage {
  .page-title {
    padding: 15px 0 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    @media (min-width: 1024px) {
      flex-direction: row;
      padding: 20px 40px;
    }
    @media (max-width: 1023px) {
      .page-title--left,
      .page-title--right {
        padding: 0 10px;
      }
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: 15px;
      line-height: normal;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      color: $primary;
      color: var(--q-color-primary) !important;
      strong {
        font-weight: 600;
      }
      @media (min-width: 1440px) {
        font-size: 18px;
      }
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    .page-title--right {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      .sorting,
      .showitems {
        margin-right: 15px;
        span {
          font-size: 15px;
          font-weight: normal;
          color: #888888;
          @media (min-width: 1681px) {
            font-size: 18px;
          }
        }
        .q-field {
          margin-left: 10px;
        }
      }
    }
  }

  /* Content */
  .page-content {
    padding: 15px;
    @media (min-width: 1024px) {
      padding: 20px;
    }
    &.favouriteItems {
      .product-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        .product-items--card {
          width: 50%;
          @media (min-width: 600px) {
            width: 33.33%;
          }
          @media (min-width: 1024px) {
            width: 25%;
          }
          @media (min-width: 1200px) {
            width: 20%;
          }
          @media (min-width: 1440px) {
            width: 16.66%;
          }
          @media (min-width: 1600px) {
            width: 16.66%;
          }
          @media (min-width: 1920px) {
            width: 14.28%;
          }
          &.list-view {
            width: 100%;
            min-height: 200px;
            display: flex;
            .product-offer-container {
              .product-offer-dropdown {
                min-width: 300px;
                top: 10px;
              }
            }
            .product-image {
              display: flex;
              width: 100%;
              min-width: 200px;
              max-width: 200px;

              a.no-underline,
              a.underline {
                width: 100%;
              }
              @media (max-width: 767px) {
                min-width: 150px;
                max-width: 150px;
              }
              @media (max-width: 360px) {
                min-width: 100px;
                max-width: 100px;
              }
            }
            .product-info {
              flex: 1 1 auto;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .product-title {
                font-size: 16px;
                line-height: 1.3;
                font-weight: bold;
              }
              .product-price-favorite {
                min-width: 160px;
                margin-left: auto;
              }
              .produc-title-price {
                display: flex;
                flex-wrap: nowrap;
                min-width: 100%;
              }
              .product-title-container {
                order: -1;
                padding-right: 40px;
              }
              .wishlist-btn {
                right: 0px;
              }
              .product-desc {
                color: $grey;
              }
              a {
                .q-card__section {
                  > .row.column {
                    flex-direction: row;
                    > .col {
                      flex: 0 0 auto;
                    }
                  }
                }
              }
              .product-cart,
              .productOption {
                width: 180px;
              }
            }
          }
        }
      }
      .product-count {
        font-size: 14px;
        color: #888888;
        margin-bottom: 15px;
        @media (min-width: 1024px) {
          font-size: 15px;
        }
        @media (min-width: 1681px) {
          font-size: 18px;
        }
      }
    }
    .product-items--card {
      min-height: 100%;
      padding: 10px;
      border: 1px solid #eeeeee;
      @media (min-width: 1024px) {
        &:hover {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
        }
      }
      @media (max-width: 599px) {
        padding: 5px;
        min-height: 320px;
      }
    }

    .text-h5 {
      font-weight: 500;
      font-family: 'Poppins', sans-serif;
      strong {
        font-size: 18px;
      }
    }
  }

  .q-breadcrumbs {
    display: inline-block;
  }
  .count-loader {
    float: right;
    right: 35px;
  }
}
.pinCart {
  .wishlistPage {
    .page-content.favouriteItems {
      .product-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        .product-items--card {
          width: 50%;
          @media (min-width: 600px) {
            width: 50%;
          }
          @media (min-width: 1024px) {
            width: 33.33%;
          }
          @media (min-width: 1280px) {
            width: 25%;
          }
          @media (min-width: 1480px) {
            width: 25%;
          }
          @media (min-width: 1600px) {
            width: 20%;
          }
          @media (min-width: 1920px) {
            width: 16.66%;
          }
          &.list-view {
            width: 100%;
          }
        }
      }
    }
  }
}
.q-infinite-scroll__loading {
  width: 100%;
}

// list view skeleton
.page-content.favouriteItems
  .product-items
  .product-items--card.list-view-skeleton {
  width: 100%;
  min-height: 200px;
  display: flex;
  .product-thumb-skeleton {
    width: 100%;
    min-width: 200px;
    max-width: 200px;
    @media (max-width: 767px) {
      min-width: 150px;
      max-width: 150px;
    }
    @media (max-width: 360px) {
      min-width: 100px;
      max-width: 100px;
    }
  }
  .product-info-skeleton {
    margin-left: 15px;
    width: 100%;
  }
  .product-skeleton-top {
    max-width: 100px;
  }
  .product-skeleton-fav {
    float: right;
  }
  .product-skeleton-title {
    max-width: 40%;
  }
  .product-skeleton-btn {
    max-width: 200px;
  }
}
</style>
